import React from 'react';
import styled from 'styled-components';
import qs from 'query-string';
import {Button} from 'antd';
import Config from '../../../data.json';

export default function MobileRedirect(props) {
  // for some payment method on mobile cannot redirect to app, ex. jkopay

  return (
    <Wrapper>
      <div className="logo">
        <img src="/images/logo.png" alt="laya" />
      </div>

      <div className="content">
        <Button
          size="large"
          type="primary"
          onClick={() => {
            window.location = `${Config.mobileAppHost}://OrderDetail${props.location.search}`;
            // checkoutFlow parameter add from backend
          }}>
          回到 拉亞漢堡 LayaNow App
        </Button>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgb(251, 249, 241);
  min-height: 100vh;

  & > .logo {
    width: 144px;
    height: 144px;
    margin-bottom: 20px;

    & > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      transform: translateY(-100px);
      border-radius: 8px;
      overflow: hidden;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.33);
    }
  }

  & > .content {
    padding: 10px;
    margin: 0 auto;
    width: 300px;
    transform: translateY(-100px);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
